<template>
<div class="inbox-center table-responsive">
  <table class="table table-sm table-bordered table-detail">
    <thead>
      <tr class="full-color-table full-purple-table">
        <th rowspan="2" class="text-center" style="width: 40px; vertical-align: middle;">No</th>
        <th rowspan="2" style="vertical-align: middle;">Sasaran RTU</th>
        <th rowspan="2" style="vertical-align: middle;">Nama Risiko</th>
        <th rowspan="2" style="vertical-align: middle;">Bidang</th>
        <th colspan="3" class="text-center">{{ lap.info.prev_column }}</th>
        <th colspan="3" class="text-center">{{ lap.info.current_column }}</th>
      </tr>
      <tr>
        <th class="text-center full-color-table full-purple-table" style="width: 60px;">K</th>
        <th class="text-center full-color-table full-purple-table" style="width: 60px;">D</th>
        <th class="text-center full-color-table full-purple-table" style="width: 60px;">K x D</th>
        <th class="text-center full-color-table full-purple-table" style="width: 60px;">K</th>
        <th class="text-center full-color-table full-purple-table" style="width: 60px;">D</th>
        <th class="text-center full-color-table full-purple-table" style="width: 60px;">K x D</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(v,k) in lap.data" :key="k">
        <td class="text-center">{{ k+1 }}</td>
        <td>{{ v.sasaran_rtu }}</td>
        <td>{{ v.name }}</td>
         <td>{{ v.bidang || '-' }}</td>
        <td class="text-center">{{ v.prev_current_k||'-' }}</td>
        <td class="text-center">{{ v.prev_current_d||'-' }}</td>
        <td class="text-center">{{ v.prev_current_kd||'-' }}</td>
        <td class="text-center">
          {{ +v.current_k.toString() }}
        </td>
        <td class="text-center">
          {{ +v.current_d.toString() }}
        </td>
        <td class="text-center">
          {{ +v.current_kd.toString() }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
</template>

<script>
import GlobalVue from '@/libs/Global'

export default{
  extends:GlobalVue,
  props:{
    lap:Object
  }
}
</script>