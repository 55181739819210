<template>
<div class="inbox-center table-responsive">
  <table class="table table-sm table-detail">
    <thead>
      <tr class="full-color-table full-purple-table">
        <th rowspan="2" class="text-center" style="width: 40px;">No</th>
        <th class="text-center" rowspan="2">Nama Risiko</th>
        <th class="text-center" rowspan="2">Sasaran RTU</th>
        <th class="text-center" rowspan="2">Sebab</th>
        <th class="text-center" rowspan="2">Dampak</th>
        <th class="text-center" colspan="3">Inheren</th>
        <th class="text-center" colspan="2">Skor Efektivitas</th>
        <th class="text-center" colspan="3">Current</th>
        <th class="text-center" rowspan="2">Penurunan %</th>
        <th class="text-center" rowspan="2">Pengendalian</th>
        <th class="text-center" rowspan="2">Mitigasi Risiko</th>
        <th class="text-center" rowspan="2">Periode</th>
        <th class="text-center" rowspan="2">PIC</th>
        <th class="text-center" colspan="3">Residual</th>
        <th class="text-center" rowspan="2">Progress Mitigasi Risiko</th>
      </tr>
      <tr>
        <th class="text-center full-color-table full-purple-table">K</th>
        <th class="text-center full-color-table full-purple-table">D</th>
        <th class="text-center full-color-table full-purple-table">KxD</th>
        <th class="text-center full-color-table full-purple-table">K</th>
        <th class="text-center full-color-table full-purple-table">D</th>
         <th class="text-center full-color-table full-purple-table">K</th>
        <th class="text-center full-color-table full-purple-table">D</th>
        <th class="text-center full-color-table full-purple-table">KxD</th>
        <th class="text-center full-color-table full-purple-table">K</th>
        <th class="text-center full-color-table full-purple-table">D</th>
        <th class="text-center full-color-table full-purple-table">KxD</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(v,k) in lap.data" :key="k">
        <td>{{ k+1 }}</td>
        <td>{{ v.mkr_name }}</td>
        <td>{{v.mkrd_sasaran_rtu}}</td>
        <td>{{ v.mkrd_sebab }}</td>
        <td>{{ v.mkrd_dampak }}</td>
        <td>{{ v.mkrd_inheren_k }}</td>
        <td>{{ v.mkrd_inheren_d }}</td>
        <td>{{ v.mkrd_inheren_kd }}</td>
        <td>{{ v.mkrd_skor_efektivitas_k }}</td>
        <td>{{ v.mkrd_skor_efektivitas_d }}</td>
        <td>{{ +v.mkrd_current_k.toString() }}</td>
        <td>{{ +v.mkrd_current_d.toString() }}</td>
        <td>{{ +v.mkrd_current_kd.toString() }}</td>
        <td>{{ v.mkrd_penurunan }}%</td>
        <td>{{ v.mkrd_pengendalian }}</td>
        <td>{{ v.mkrd_mitigasi_risiko }}</td>
        <td>{{ v.mkrd_periode_mulai }} - {{v.mkrd_periode_akhir}}</td>
        <td>{{ v.mp_description }}</td>
        <td>{{ v.mkrd_residual_k }}</td>
        <td>{{ v.mkrd_residual_d }}</td>
        <td>{{ v.mkrd_residual_kd }}</td>
        <td>{{ v.mkrd_proses_mitigasi }}</td>
      </tr>
    </tbody>
  </table>
</div>
</template>

<script>
import GlobalVue from '@/libs/Global'

export default{
  extends:GlobalVue,
  props:{
    lap:Object
  }
}
</script>