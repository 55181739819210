<template>
<div class="inbox-center table-responsive">
  <table class="table table-bordered">
    <thead>
      <tr class="full-color-table full-purple-table">
        <th class="text-center" style="width: 30px;">No</th>
        <th>Nama Kantor</th>
        <th>Nama Risiko</th>
        <th>Bidang</th>
        <th>Pengendalian yang Dilakukan</th>
        <th>Tgl Terjadi</th>
        <th>Nilai Kerugian (Rp)</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(v,k) in lap.data" :key="k">
        <td class="text-center">{{ k+1 }}</td>
        <td>{{ v.mo_name }}</td>
        <td>{{ v.mr_name }}</td>
        <td>{{ v.mb_name || '-' }}</td>
        <td>
          <ul v-if="v.kendali.length">
            <li
              v-for="(v2,k2) in v.kendali"
              :key="k+'.'+k2"
            >
             {{ v2.mrk_name }} 
            </li>
          </ul>
          <span v-else>-</span>
        </td>
        <td>{{ v.ml_tgl_terjadi | moment('DD.MM.YYYY') }}</td>
        <td>Rp {{ (v.ml_nilai_kerugian||0).format() }}</td>
      </tr>
    </tbody>
  </table>
</div>
</template>

<script>
import GlobalVue from '@/libs/Global'

export default{
  extends:GlobalVue,
  props:{
    lap:Object
  }
}
</script>