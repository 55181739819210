<template>
<div class="inbox-center table-responsive">
  <table class="table table-bordered">
    <thead>
      <tr class="full-color-table full-purple-table">
        <th rowspan="2" style="width: 40px; vertical-align: middle;" class="text-center">No</th>
        <th rowspan="2" style="vertical-align: middle;min-width:180px">Unit Kerja</th>
        <th rowspan="2" style="vertical-align: middle;min-width:180px">Sasaran RTU</th>
        <th rowspan="2" style="vertical-align: middle;min-width:180px">Nama Risiko</th>
        <th rowspan="2" style="vertical-align: middle;min-width:180px">Bidang</th>
        <th rowspan="2" style="vertical-align: middle;min-width:180px">Sebab</th>
        <th rowspan="2" style="vertical-align: middle;min-width:180px">Dampak</th>
        <th colspan="3" class="text-center">Nilai Inheren</th>
        <th rowspan="2" style="vertical-align: middle;min-width:180px">Pengendalian</th>
        <th colspan="3" class="text-center">Nilai Current</th>
        <th rowspan="2" style="vertical-align: middle;min-width:180px">Rencana Perlakuan/Mitigasi Risiko</th>
        <th colspan="3" class="text-center">Target Nilai Residual</th>
      </tr>
      <tr>
        <th class="full-color-table full-purple-table text-center" style="width: 60px;">K</th>
        <th class="full-color-table full-purple-table text-center" style="width: 60px;">D</th>
        <th class="full-color-table full-purple-table text-center" style="min-width: 60px;">K x D</th>
        <th class="full-color-table full-purple-table text-center" style="width: 60px;">K</th>
        <th class="full-color-table full-purple-table text-center" style="width: 60px;">D</th>
        <th class="full-color-table full-purple-table text-center" style="min-width: 60px;">K x D</th>
        <th class="full-color-table full-purple-table text-center" style="width: 60px;">K</th>
        <th class="full-color-table full-purple-table text-center" style="width: 60px;">D</th>
        <th class="full-color-table full-purple-table text-center" style="min-width: 60px;">K x D</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(v,k) in lap.data" :key="k">
        <td class="text-center">{{ k+1 }}</td>
        <td>{{ v.unit_kerja }}</td>
        <td>{{ v.sasaran_rtu }}</td>
        <td>{{ v.name }}</td>
        <td>{{ v.bidang || '-' }}</td>
        <td>
          <ul style="padding-left: 20px;">
            <li v-for="(v2,k2) in v.sebab" :key="k+'.'+k2">
              {{ v2.nama }}
            </li>
          </ul>
        </td>
        <td>
          <ul style="padding-left: 20px;">
            <li v-for="(v2,k2) in v.dampak" :key="k+'.'+k2">
              {{ v2.nama }}
            </li>
          </ul>
        </td>
        <td class="text-center">{{ v.inheren_k||'-' }}</td>
        <td class="text-center">{{ v.inheren_d||'-' }}</td>
        <td class="text-center">{{ v.inheren_kd||'-' }}</td>
        <td>
          <ul style="padding-left: 20px;">
            <li v-for="(v2,k2) in v.kendali" :key="k+'.'+k2">
              {{ v2.nama }}
            </li>
          </ul>
        </td>
        <td class="text-center">{{ +v.current_k.toString()||'-' }}</td>
        <td class="text-center">{{ +v.current_d.toString()||'-' }}</td>
        <td class="text-center">{{ +v.current_kd.toString()||'-' }}</td>
        <td>
          <ul 
            v-if="v.mitigasi.length"
            style="padding-left: 20px;"
          >
            <li v-for="(v2,k2) in v.mitigasi" :key="k+'.'+k2">
              {{ v2.nama }}
            </li>
          </ul>
          <span v-else>-</span>
        </td>
        <td class="text-center">{{ v.risidual_k||'-' }}</td>
        <td class="text-center">{{ v.risidual_d||'-' }}</td>
        <td class="text-center">{{ v.risidual_kd||'-' }}</td>
      </tr>
    </tbody>
  </table>
</div>
</template>

<script>
import GlobalVue from '@/libs/Global'

export default{
  extends:GlobalVue,
  props:{
    lap:Object
  }
}
</script>