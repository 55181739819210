<template>
<div class="inbox-center table-responsive">
  <table class="table table-bordered table-bordered">
    <thead>
      <tr class="full-color-table full-purple-table">
        <th class="text-center" style="width: 40px;">No</th>
        <th>Deskripsi</th>
        <th class="text-center" style="max-width: 100px;">Tanggal</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(v,k) in lap.data" :key="k">
        <td class="text-center">{{ k+1 }}</td>
        <td>{{ v.name }}</td>
        <td v-if="v.date" class="text-center">{{ v.date | moment('DD.MM.YYYY') }}</td>
        <td v-else class="text-center">-</td>
      </tr>
    </tbody>
  </table>
</div>
</template>

<script>
import GlobalVue from '@/libs/Global'

export default{
  extends:GlobalVue,
  props:{
    lap:Object
  }
}
</script>