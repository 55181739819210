<template>
<div class="inbox-center table-responsive">
  <table class="table table-sm table-detail">
    <thead>
      <tr class="full-color-table full-purple-table">
        <th class="text-center" style="vertical-align: middle;">No</th>
        <th style="vertical-align: middle;min-width:180px">Nama Risiko</th>
        <th style="vertical-align: middle;min-width:180px">Bidang</th>
        <th style="vertical-align: middle;min-width:180px">Kategori</th>
        <th style="vertical-align: middle;min-width:180px">Unit Kerja</th>
        <th style="vertical-align: middle;min-width:100px">Skor Inheren</th>
        <th style="vertical-align: middle;min-width:100px">Skor Current</th>
        <th style="vertical-align: middle;min-width:100px">Skor Residual</th>
        <th style="vertical-align: middle;min-width:100px">Pengendalian</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(v, k) in lap.data" :key="k">
        <td>{{ k+1 }}</td>
        <td>{{ v.name }}</td>
        <td>{{ v.bidang || '-' }}</td>
        <td>{{ v.kategori }}</td>
        <td>{{ v.unit_kerja }}</td>
        <td>{{ v.inheren_kd }}</td>
        <td>{{ v.current_kd.toString() }}</td>
        <td>{{ v.risidual_kd }}</td>
        <td>
          <ul>
            <li v-for="(v2,k2) in v.kendali" :key="k+'.'+k2">
              {{v2.nama}}
            </li>
          </ul>
        </td>
      </tr>
    </tbody>
  </table>
</div>
</template>

<script>
import GlobalVue from '@/libs/Global'

export default{
  extends:GlobalVue,
  props:{
    lap:Object
  }
}
</script>