<template>
<div class="container-fluid">
  <PageTitle noAdd>
    <template v-if="pageSlug" #title>
      <span v-if="pageSlug=='10-risiko-teratas'">
        Laporan 10 Risiko Teratas
      </span>
      <span v-else-if="pageSlug=='register-risiko'">
        Laporan Register Risiko
      </span>
      <span v-else-if="pageSlug=='mitigasi-risiko'">
        Laporan Mitigasi Risiko
      </span>
      <span v-else-if="pageSlug=='laporan-perbandingan'">
        Laporan Perbandingan
      </span>
      <span v-else-if="pageSlug=='led'">
        Laporan Loss Event Database
      </span>
      <span v-else-if="pageSlug=='pelaporan-mr'">
        Pelaporan MR
      </span>
      <span v-else-if="pageSlug=='risiko-korporat'">
        Laporan Risiko Korporat
      </span>
    </template>
  </PageTitle>

  <template v-if="isList">
    <div class="card">
      <VTab>
        <template slot="link">
          <VTabLink :step.sync="tab" option="bulan">BULAN</VTabLink>
          <VTabLink :step.sync="tab" option="triwulan">TRIWULAN</VTabLink>
        </template>
        <template slot="content">
          <VTabContent :step.sync="tab" :option="tab">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4">
                  <div class="card_report_header">
                    <h6 class="card-title mb-0">10 Risiko Teratas</h6>
                  </div>
                  <div class="card_report">
                    <p>
                      Laporan berdasarkan 10 risiko teratas per-tahun.
                    </p>
                    <router-link 
                      :to="{name: $route.name, params:{periode: tab, pageSlug: '10-risiko-teratas'}}"
                      class="btn waves-effect waves-light btn-outline-info">
                      <i class="ti-file"></i>&nbsp;Lihat Laporan
                    </router-link>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="card_report_header">
                    <h6 class="card-title mb-0">Register Risiko</h6>
                  </div>
                  <div class="card_report">
                    <p>
                      <!-- Laporan .. per tahun -->
                    </p>
                    <router-link 
                      :to="{name: $route.name, params:{periode: tab, pageSlug: 'register-risiko'}}"
                      class="btn waves-effect waves-light btn-outline-info">
                      <i class="ti-file"></i>&nbsp;Lihat Laporan
                    </router-link>
                  </div>
                </div>


                <div class="col-lg-4">
                  <div class="card_report_header">
                    <h6 class="card-title mb-0">Mitigasi Risiko</h6>
                  </div>
                  <div class="card_report">
                    <p>
                      <!-- Laporan .. per tahun -->
                    </p>
                    <router-link 
                      :to="{name: $route.name, params:{periode: tab, pageSlug: 'mitigasi-risiko'}}"
                      class="btn waves-effect waves-light btn-outline-info">
                      <i class="ti-file"></i>&nbsp;Lihat Laporan
                    </router-link>
                  </div>
                </div>


                <div class="col-lg-4">
                  <div class="card_report_header">
                    <h6 class="card-title mb-0">Laporan Perbandingan</h6>
                  </div>
                  <div class="card_report">
                    <p>
                      <!-- Laporan .. per tahun -->
                    </p>
                    <router-link 
                      :to="{name: $route.name, params:{periode: tab, pageSlug: 'laporan-perbandingan'}}"
                      class="btn waves-effect waves-light btn-outline-info">
                      <i class="ti-file"></i>&nbsp;Lihat Laporan
                    </router-link>
                  </div>
                </div>

                <div 
                  v-if="tab!='triwulan'"
                  class="col-lg-4"
                >
                  <div class="card_report_header">
                    <h6 class="card-title mb-0">Loss Event Database</h6>
                  </div>
                  <div class="card_report">
                    <p>
                      <!-- Laporan .. per tahun -->
                    </p>
                    <router-link 
                      :to="{name: $route.name, params:{periode: tab, pageSlug: 'led'}}"
                      class="btn waves-effect waves-light btn-outline-info">
                      <i class="ti-file"></i>&nbsp;Lihat Laporan
                    </router-link>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="card_report_header">
                    <h6 class="card-title mb-0">Pelaporan MR</h6>
                  </div>
                  <div class="card_report">
                    <p>
                      <!-- Laporan .. per tahun -->
                    </p>
                    <router-link 
                      :to="{name: $route.name, params:{periode: tab, pageSlug: 'pelaporan-mr'}}"
                      class="btn waves-effect waves-light btn-outline-info">
                      <i class="ti-file"></i>&nbsp;Lihat Laporan
                    </router-link>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="card_report_header">
                    <h6 class="card-title mb-0">Risiko Korporat</h6>
                  </div>
                  <div class="card_report">
                    <p>
                      <!-- Laporan .. per tahun -->
                    </p>
                    <router-link 
                      :to="{name: $route.name, params:{periode: tab, pageSlug: 'risiko-korporat'}}"
                      class="btn waves-effect waves-light btn-outline-info">
                      <i class="ti-file"></i>&nbsp;Lihat Laporan
                    </router-link>
                  </div>
                </div>

              </div>
            </div>
          </VTabContent>
        </template>
      </VTab>
    </div>
  </template>
  <div
    v-else
  >
    <div class="card" v-if="Object.keys($route.query).length == 0">
      <div class="card-header">
        <h5 class="card-title mb-0">Filter</h5>
      </div>
      <div class="card-body">
        <b-row class='justify-content-end' :cols-md="colMd">
            <b-col
            v-if="(['pelaporan-mr'].indexOf(pageSlug)<=-1 && ['risiko-korporat'].indexOf(pageSlug)<=-1 ) && mrLevel"
          >
            <b-form-group label="Level">
              <VSelect 
                v-model="filter.level" 
                placeholder="-- Pilih --"
                :options="mrLevel" 
                :reduce="v=>String(v.value)"
                @input="filterLevel"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="(['pelaporan-mr'].indexOf(pageSlug)<=-1 && ['risiko-korporat'].indexOf(pageSlug)<=-1 )&& mrOffice"
          >
            <b-form-group label="Kantor">
              <b-overlay :show="loadingKantor" >
                <VSelect 
                  v-model="filter.office" 
                  placeholder="-- Pilih --"
                  :options="mrOffice" 
                  :reduce="v=>String(v.value)"
                  @input="getUnitKerja"
                />
              </b-overlay>
            </b-form-group>
          </b-col>
          <b-col
            v-if="(['pelaporan-mr'].indexOf(pageSlug)<=-1 && ['risiko-korporat'].indexOf(pageSlug)<=-1 )"
          >
            <b-form-group :label="isPusat ? 'Group' : 'Unit Kerja'" class="w-100">
              <b-overlay
                  :show="loadingUnitKerja"
                >
              <VSelect 
                v-model="filter.unit_kerja" 
                placeholder="-- Pilih --"
                :options="mrUnitKerja" 
                :reduce="v=>String(v.value)" 
              />
              </b-overlay>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Tahun" class="w-100">
              <VSelect 
                v-model="filter.tahun" 
                placeholder="-- Pilih --"
                :options="periodeTahun" 
                :reduce="v=>String(v.value)" 
              />
            </b-form-group>
          </b-col>
          <b-col>
            <template v-if="$route.params.periode=='bulan'">
              <b-form-group label="Bulan" class="w-100">
                <VSelect 
                  v-model="filter.bulan" 
                  placeholder="-- Pilih --"
                  :options="periodeBulan" 
                  :reduce="v=>String(v.value)" 
                />
              </b-form-group>
            </template>
            <template v-else>
              <b-form-group label="Triwulan" class="w-100">
                <VSelect 
                  v-model="filter.triwulan" 
                  placeholder="-- Pilih --"
                  :options="periodeTriwulan" 
                  :reduce="v=>String(v.value)" 
                />
              </b-form-group>
            </template>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md=8> </b-col>
          <b-col md=2>
            <button 
              type="button" 
              class="btn btn-secondary btn-block" 
              @click="doReset"
            > 
              Reset
            </button>
          </b-col>
          <b-col md=2>
            <button 
              type="button" 
              class="btn btn-info btn-block" 
              @click="doGetLap"
            > 
              Tampilkan
            </button>
          </b-col>
        </b-row>
      </div>
    </div>
    <div 
      class="card"          
    > 
      <div 
        v-if="!laporan && !Object.keys($route.query||{}).length"
        class="card-body"
      >
        <div class="wrap_no_data" align="center">
          <img :src="baseUrl+'/images/nodata.svg'" class="nodata-img"/>
          <b-row align-h="center">
            <b-col md="5">
              <h3>Isi data pada bagian Filter, lalu klik "Tampilkan" untuk menampilkan laporan.</h3>
            </b-col>
          </b-row>
        </div>
      </div>
      <template v-else-if="laporan">
        <div class="row">
          <div class="col-lg-12 col-md-8">
            <div class="card-body">
              <template v-if="!laporan.data.length">
                <div class="wrap_no_data" align="center">
                  <img :src="baseUrl+'/images/nodata.svg'" class="nodata-img"/>
                  <h3>Tidak Ada Laporan.</h3>
                </div>
              </template>
              <template v-else>
                <b-row>
                  <b-col md="3">
                    <img class="img-responsive" :src="baseUrl+'/images/logo-jasa-raharja-horizontal.png'" />
                  </b-col>
                  <b-col md="3" offset-md="6" align="right">
                    <b-dropdown right variant="outline-info" text="outline-info">
                      <template #button-content>
                        <i class="ti-download"></i> Export
                      </template>
                      <b-dropdown-item @click="doExport('&exptype=excel')">Excel</b-dropdown-item>
                      <b-dropdown-item @click="doExport('&exptype=pdf')">PDF</b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                </b-row>
                
                <div class="row" >
                  <div class="col-md-12">
                    <div class="wrap_text_report">
                      <h3 class="card-title mb-1">{{laporan.info.heading}}</h3>
                      <h4 class="card-title mb-0">{{laporan.info.sub_heading}}</h4>
                      <p v-if="laporan.info.office">{{laporan.info.office}}</p>
                      <p v-if="laporan.info.periode">
                        <small v-if="laporan.info.periode=='triwulan'">Triwulan {{laporan.info.triwulan}}</small>
                        <small v-else>Bulan {{ '2021-'+laporan.info.bulan+'-01' | moment('MMMM') }}</small>
                      </p>
                    </div>
                  </div>
                  <div class="col-md-12 text-right">
                    <p style="font-size:12px">Diakses oleh <span class="fw-bold">{{laporan.info.access_by}}</span> pada tanggal <span class="fw-bold">{{laporan.info.access_date}}</span></p>
                  </div>
                </div>

                <div class="card-body p-0 p-b-0 p-t-0">
                  <div class="card b-all shadow-none">

                    <Laporan10RisikoTeratas 
                      v-if="pageSlug=='10-risiko-teratas'"
                      :lap="laporan" 
                    />
                    <LaporanRegRisiko 
                      v-else-if="pageSlug=='register-risiko'"
                      :lap="laporan" 
                    />
                    <LaporanMitigasi 
                      v-else-if="pageSlug=='mitigasi-risiko'"
                      :lap="laporan" 
                    />
                    <LaporanPerbandingan 
                      v-else-if="pageSlug=='laporan-perbandingan'"
                      :lap="laporan" 
                    />
                    <LaporanLed 
                      v-else-if="pageSlug=='led'"
                      :lap="laporan" 
                    />
                    <LaporanPelaporanMr 
                      v-else-if="pageSlug=='pelaporan-mr'"
                      :lap="laporan" 
                    />
                    <LaporanRisikoKorporat 
                      v-else-if="pageSlug=='risiko-korporat'"
                      :lap="laporan" 
                    />
                    
                    <div 
                      v-if="laporan.data.length"
                      class="table-responsive"
                    >
                      <table class="table no-border" >
                        <tbody>
                          <tr v-if="laporan.info.right_signature">
                            <td width="60%">
                              <p v-if="!laporan.info.right_signature">
                                {{laporan.info.left_signature.at}}
                              </p>
                              <p>Mengetahui,</p>
                              <br>
                              <br>
                              <br>
                              <p><strong><u>{{laporan.info.left_signature.by}}</u></strong></p>
                              <p>{{laporan.info.left_signature.position}}</p>
                            </td>
                            <td v-if="laporan.info.right_signature" width="60%">
                              <p>{{laporan.info.left_signature.at}}</p>
                              <br>
                              <br>
                              <br>
                              <p><strong><u>{{laporan.info.right_signature.by}}</u></strong></p>
                              <p>{{laporan.info.right_signature.position}}</p>
                            </td>
                          </tr>
                          <tr v-else>
                            <td width="60%"> </td>
                            <td width="60%">
                              <p v-if="!laporan.info.right_signature">
                                {{laporan.info.left_signature.at}}
                              </p>
                              <p>Mengetahui,</p>
                              <br>
                              <br>
                              <br>
                              <p><strong><u>{{laporan.info.left_signature.by}}</u></strong></p>
                              <p>{{laporan.info.left_signature.position}}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</div>
</template>

<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import Gen from '@libs/Gen'

import VTab from '@/components/VTab/VTab.vue'
import VTabLink from '@/components/VTab/VTabLink.vue'
import VTabContent from '@/components/VTab/VTabContent.vue'

import Laporan10RisikoTeratas from '@/views/Laporan10RisikoTeratas.vue'
import LaporanRegRisiko from '@/views/LaporanRegRisiko.vue'
import LaporanMitigasi from '@/views/LaporanMitigasi.vue'
import LaporanPerbandingan from '@/views/LaporanPerbandingan.vue'
import LaporanLed from '@/views/LaporanLed.vue'
import LaporanPelaporanMr from '@/views/LaporanPelaporanMr.vue'
import LaporanRisikoKorporat from '@/views/LaporanRisikoKorporat.vue'

let _ = global._
let Swal = global.Swal
export default {
  extends: GlobalVue,
  components: {
    PageTitle,
    VTab, VTabLink, VTabContent,
    Laporan10RisikoTeratas,
    LaporanRegRisiko,
    LaporanMitigasi,
    LaporanPerbandingan,
    LaporanLed,
    LaporanPelaporanMr,
    LaporanRisikoKorporat
  },
  data() {
    return {
      tab: "bulan",
      laporan:false,
      periodeBulan: [
				{
					label: 'Januari',
					value: 1
				},
				{
					label: 'Februari',
					value: 2
				},
				{
					label: 'April',
					value: 4
				},
				{
					label: 'Mei',
					value: 5
				},
				{
					label: 'Juli',
					value: 7
				},
				{
					label: 'Agustus',
					value: 8
				},
				{
					label: 'Oktober',
					value: 10
				},
				{
					label: 'November',
					value: 11
				},
			],
      periodeTahun: [],
      periodeTriwulan:[
        {value:1, label:'Ke-1'},
        {value:2, label:'Ke-2'},
        {value:3, label:'Ke-3'},
        {value:4, label:'Ke-4'},
      ],
      mrOffice: [],
      mrUnitKerja: [],
      mrLevel: [],
      loadingUnitKerja: false,
      loadingKantor: false
    }
  },
  computed:{
    colMd(){
			return  [this.mrLevel, this.mrOffice, this.mrUnitKerja, this.periodeBulan, this.periodeTahun].filter(v => v).length
    },
    isFilterDisabled(){
      return (
        (['pelaporan-mr'].indexOf(this.pageSlug)<=-1 && ['risiko-korporat'].indexOf(this.pageSlug)<=-1) &&
        (
          (this.mrOffice.length && !this.filter.office) || !this.filter.unit_kerja
        )
      ) || 
      !this.filter.tahun || 
      (
        (
          (
            this.$route.params.periode=='triwulan' && !this.filter.triwulan 
          ) ||
          (
            this.$route.params.periode=='bulan' && !this.filter.bulan 
          )
        )
      )
    },
    isPusat(){
      return _.filter(this.mrOffice, (v) => {
        return v.value == this.filter.office && v.level=='pusat';
      }).length;
    },
    columnCount() {
      if ((['pelaporan-mr'].indexOf(this.pageSlug)<=-1 && ['risiko-korporat'].indexOf(this.pageSlug)<=-1 )&& this.mrOffice.length) {
        return 4
      } else if(['pelaporan-mr'].indexOf(this.pageSlug)<=-1 && ['risiko-korporat'].indexOf(this.pageSlug)<=-1) {
        return 3
      }
      return 2
    }
  },
  methods: {
    doGetLap(){
      if(this.isFilterDisabled){
        return Swal.fire({
          icon:'warning',
          title:'Semua pilihan wajib dipilih!'
        })
      }
      const {periode, pageSlug} = this.$route.params;
      window.open(this.$router.resolve({params: { periode, pageSlug}, query: this.filter}).href, '_blank')
    },
    doReset(){
      this.filter={}
      if(Object.keys(this.$route.query).length > 0){
        this.$router.push({name:this.$route.name,params:{pageSlug:this.pageSlug}, query:{page:1}});
      }
    },
    doExport(p){
      let reqUrl = process.env.VUE_APP_API_URL + "/api/bo/get/"+this.modulePage+'/detail'
      
      this.$set(this.filter, 'periode', this.$route.params.periode)
      let params = Object.assign(this.filter, {type: this.pageSlug, export:1, token: Gen.getCookie("bo_auth")||""})
      params = new URLSearchParams(params).toString()

      reqUrl += '?'+params
      if(p) reqUrl += p

      window.open(reqUrl, '_blank')
    },
    getUnitKerja(){
      const officeId = this.filter.office;
      const officeIndex = this.mrOffice.findIndex(v => v.value == officeId)
      const officeLevel = this.mrOffice[officeIndex].level
        const payload = { 
          params: {
            type: "getUnitKerja",
            officeLevel,
          }
        }
          this.loadingUnitKerja=true
          this.$set(this.filter, 'unit_kerja', null);
          Gen.apiRest("/get/" + this.modulePage + '/detail', payload).then(
            res => {
              this.loadingUnitKerja=false
              this.mrUnitKerja = res.data.data
            }
          ).catch(err => {
            this.loadingUnitKerja=false
            console.log(err)
          })
    },
    checkCol() {
    },
    filterLevel(){
      const level = this.filter.level;
      const payload = {
        params: {
          type: 'getKantor',
          level
        }
      }
      this.loadingKantor = true;
      this.$set(this.filter, 'office', null);
      Gen.apiRest("/get/" + this.modulePage + '/detail', payload).then(
        res => {
          this.loadingKantor=false
          this.mrOffice = res.data.data
        }
      ).catch(err => {
        this.loadingKantor=false
        console.log(err)
      })
    }
  },
  mounted(){
    this.apiGet()
    const query = Object.keys(this.$route.query);
    if(query.length > 0){
      query.forEach(v => {
        this.filter[v] = this.$route.query[v];
      });
      this.$set(this.filter, 'periode', this.$route.params.periode)
      this.loadingOverlay=true
      Gen.apiRest(
        "/get/"+this.modulePage+'/detail',
        {
          params:Object.assign(this.filter, {
            type:this.pageSlug
          })
        },
        'GET'
      ).then((resp)=>{
        this.$set(this,'laporan', resp.data.laporan)
        this.loadingOverlay=false
      }).catch(err => {
        Swal.fire({
          icon:'error',
          title: err.response.data.message
          
        }).then(() => {
          this.loadingOverlay=false
        })
      })
    }
    this.checkCol()
  },
  watch: {
    $route(){
      this.apiGet()
    },
    '$route.params.periode'(){
      this.$set(this,'filter',{})
      this.$set(this,'laporan',false)
    },
    loadingOverlay(val) {
      if (val == false) this.checkCol()
    }
  }
}
</script>