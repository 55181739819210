<template>
<div class="inbox-center table-responsive">
  <table class="table table-sm table-detail">
    <thead>
      <tr class="full-color-table full-purple-table">
        <th rowspan="2" class="text-center" style="vertical-align: middle;">No</th>
        <th rowspan="2" style="vertical-align: middle;min-width:180px">Nama Risiko</th>
        <th rowspan="2" style="vertical-align: middle;min-width:180px">Bidang</th>
        <th colspan="3" class="text-center">Nilai Current</th>
        <th rowspan="2" style="vertical-align: middle;min-width:180px">Progress Mitigasi Risiko</th>
        <th rowspan="2" class="text-center" style="vertical-align: middle;">Perkiraan Biaya (Rp)
        </th>
        <th rowspan="2" class="text-center" style="vertical-align: middle;">Periode</th>
        <th rowspan="2" class="text-center" style="vertical-align: middle;">PIC</th>
      </tr>
      <tr>
        <th style="width: 40px;" class="full-color-table full-purple-table text-center">K</th>
        <th style="width: 40px;" class="full-color-table full-purple-table text-center">D</th>
        <th style="width: 60px;" class="full-color-table full-purple-table text-center">K x D</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(v,k) in lap.data">
        <tr :key="k">
          <td 
            class="text-center"
            :rowspan="v.mitigasi.length+1"
          >
            {{ k+1 }}
          </td>
          <td :rowspan="v.mitigasi.length+1">{{ v.name }}</td>
          <td :rowspan="v.mitigasi.length+1">{{ v.bidang || '-' }}</td>
          <td 
            class="text-center"
            :rowspan="v.mitigasi.length+1"
          >
            {{ +v.current_k.toString() }}
          </td>
          <td 
            class="text-center"
            :rowspan="v.mitigasi.length+1"
          >
            {{ +v.current_d.toString() }}
          </td>
          <td 
            class="text-center"
            :rowspan="v.mitigasi.length+1"
          >
            {{ +v.current_kd.toString() }}
          </td>
          <td v-if="!v.mitigasi.length" colspan="4">
          </td>
        </tr>
        <template v-if="v.mitigasi.length">
          <tr v-for="(v2,k2) in v.mitigasi" :key="k+'.'+k2">
            <td class="text-left">{{ v2.nama }}</td>
            <td class="text-left">Rp {{ v2.biaya.format() }}</td>
            <td class="text-left">
              {{ v2.jadwal_awal | moment('D.MM.YYYY') }} s/d 
              <span class="d-block">{{ v2.jadwal_akhir | moment('D.MM.YYYY') }}</span>
            </td>
            <td class="text-left">{{ v2.pic }}</td>
          </tr>
        </template>
      </template>
    </tbody>
  </table>
</div>
</template>

<script>
import GlobalVue from '@/libs/Global'

export default{
  extends:GlobalVue,
  props:{
    lap:Object
  }
}
</script>